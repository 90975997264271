import React from 'react'
import PropTypes from 'prop-types'
import {StaticQuery, graphql} from 'gatsby'
import Header from './Header/header'
import './layout.css'
import * as styles from './layout.module.css'

const Layout = ({children, img}) => {
  const getContenClasses = img => {
    if (img) {
      const contentImg = `content${img}`
      return `${styles.content} ${styles[contentImg]}`
    }
    return ''
  }
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Header siteTitle={data.site.siteMetadata.title} />
          <div
            style={{
              margin: `0 auto`,
              maxWidth: 1280,
              padding: `0px 1.0875rem 1.45rem`,
              paddingTop: 0
            }}
            className={getContenClasses(img)}
          >
            <main>{children}</main>
          </div>
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
