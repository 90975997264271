import {Link} from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import * as styles from './header.module.css'
import {Location} from '@reach/router'

const Header = ({siteTitle, location}) => (
  <header className={styles.header}>
    <div className={styles.container}>
      <h1 className={styles.title}>
        <Link to="/">{siteTitle}</Link>
      </h1>
      {location.pathname !== '/' && (
        <div className={styles.linkContainer}>
          <Link to="/">Etusivu</Link>
          <Link to="/cv">CV & Referenssit</Link>
          <Link to="/palvelut">Palvelut</Link>
          <Link to="/yhteystiedot">Yhteystiedot</Link>
        </div>
      )}
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default props => <Location>{locationProps => <Header {...locationProps} {...props} />}</Location>
